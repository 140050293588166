import React, { useState } from "react";
// import emailjs from "emailjs-com";
import "./ContactUs.css"; // Make sure to create and style this CSS file for custom styles

const Terms = () => {
  return (
    <div className="contact-us-section">
      <div className="container">
        <h2 className="section-title">COMING SOON</h2>
      </div>
    </div>
  );
};

export default Terms;
